import {Card, Col, Row} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {adminPremission} from '../../../../utils/role'

const DashboardStatistics = () => {
  return (
    <>
      {adminPremission() ? (
        <Row className='g-5 g-xl-8 mb-5 mb-xl-10'>
          <Col xl={3}>
            <Card className='bg-body hoverable text-center'>
              <Card.Body>
                <i className='las la-ticket-alt fs-3x'></i>
                <h1 className='text-gray-900 fw-bold mt-2 display-6'>158</h1>
                <div className='fw-semibold text-gray-400'>New Ticket</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-primary hoverable text-center'>
              <Card.Body>
                <i className='las la-user fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>225</h1>
                <div className='fw-semibold text-white'>Assigned</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-info hoverable text-center'>
              <Card.Body>
                <i className='las la-hourglass-half fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>130</h1>
                <div className='fw-semibold text-white'>In Progress</div>
              </Card.Body>
            </Card>
          </Col>  
          <Col xl={3}>
            <Card className='bg-body bg-warning hoverable text-center'>
              <Card.Body>
                <i className='las la-tv fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>75</h1>
                <div className='fw-semibold text-white'>Pending for Parts/Software</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-warning hoverable text-center'>
              <Card.Body>
                <i className='las la-users fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>20</h1>
                <div className='fw-semibold text-white'>Pending from Customer</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-success hoverable text-center'>
              <Card.Body>
                <i className='lar la-check-circle  fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>80</h1>
                <div className='fw-semibold text-white'>Completed</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-dark hoverable text-center'>
              <Card.Body>
                <i className='las la-times-circle fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>03</h1>
                <div className='fw-semibold text-white'>Cancelled</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-danger hoverable text-center'>
              <Card.Body>
                <i className='las la-ban fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>35</h1>
                <div className='fw-semibold text-white'>Reject</div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row className='g-5 g-xl-8 mb-5 mb-xl-10'>
          <Col xl={3}>
            <Card className='bg-body bg-dark hoverable text-center'>
              <Card.Body>
              <i className='las la-ticket-alt fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>150</h1>
                <div className='fw-semibold text-white'>Total Tickets</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-success hoverable text-center'>
              <Card.Body>
              <i className='lar la-check-circle  fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>150</h1>
                <div className='fw-semibold text-white'>Completed Tickets</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-warning hoverable text-center'>
              <Card.Body>
                <i className='las la-tv fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>150</h1>
                <div className='fw-semibold text-white'>Pending Tickets</div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={3}>
            <Card className='bg-body bg-danger hoverable text-center'>
              <Card.Body>
                <i className='las la-clock fs-3x text-white'></i>
                <h1 className='text-white fw-bold mb-2 mt-2 display-6'>150</h1>
                <div className='fw-semibold text-white'>Delayed Tickets</div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  )
}

export {DashboardStatistics}
