/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
}

const TopPerformingServiceCenters: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Top Performing Service Centers</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'>
            {/* begin::Table head */}
            <thead>
              <tr className='fs-6 fw-bold text-gray-400 border-bottom-0 text-uppercase'>
                <th>Service Center</th>
                <th>Installation</th>
                <th>Repair Service</th>
                <th>Out Country Service</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600'>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    V S Enterprises
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    250
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Digital Service (Chhindwara)
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    250
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Jai Maa Bhagwati Enterprises
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    250
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Video Care (Bhopal)
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    250
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    24/12/2022
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    250
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {TopPerformingServiceCenters}
