/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {adminPremission} from '../../../../utils/role'

type Props = {
  className: string
}

const ServiceCenterWiseOpenTickets: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Service Center Wise Open Titcket</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'>
            {/* begin::Table head */}
            <thead>
              <tr className='fs-6 fw-bold text-gray-400 border-bottom-0 text-uppercase'>
                <th>Service Center</th>
                <th className='text-end' style={{width:'150px'}}>Open Ticket</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600'>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Shree Sai Services
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>25</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Breakneck Technologies Private Limited
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>16</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Jai Maa Bhagwati Enterprises
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    V S Enterprises
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    J P Technologies
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>10</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Digital Service (Chhindwara)
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    C M Electronics
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Ritika Sales And Services
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Video Care (Bhopal)
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Amruta Services
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Sunshine Sales & Service
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Care Infotech
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Divya Enterprises
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Swami Services
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mobitech Solution
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Ekhankar Mobile Shop
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Pioneer Refrigeration
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Smartech Service Corporation - Sector 6 Noida
                  </a>
                </th>
                <td className='text-end'>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>11</div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {ServiceCenterWiseOpenTickets}
