const QUERIES = {
  USERS_LIST: 'users-list',
  SERVICECENTERS_LIST: 'servicecenters-list',
  // SENDRECEIVESPAREPARTSLISTS_LIST: 'servicecenters-list',
  DISTRIBUTORS_LIST: 'distributors-list',
  MODELMANAGEMENTS_LIST: 'modelmanagements-list',
  CUSTOMERSUPPORTS_LIST: 'customersupports-list',
  SUPPORTAGENTS_LIST: 'supportagents-list',
  CATEGORYMANAGEMENTS_LIST: 'categorymanagements-list',
  PRODUCTMANAGEMENTS_LIST: 'productmanagements-list',
  PARTMANAGEMENTS_LIST: 'partmanagements-list',
  CUSTOMERMANAGEMENTS_LIST: 'customermanagements-list',
  TICKETMANAGEMENTS_LIST: 'ticketmanagements-list',
  SENDRECEIVESPAREPARTS_LIST: 'sendreceivesparepartpage-list',
  CLAIMMANAGEMENTS_LIST: 'claimmanagements-list',
  SUPPORTTICKETARCHIVES_LIST: 'supportticketarchives-list',
  CLAIMARCHIVES_LIST: 'claimarchives-list',
  SENTRECEIVEPARTS_LIST: 'sentreceiveparts-list',
  SUPPORTTICKETSANALYSISREPORTS_LIST: 'supportticketsanalysisreports-list',
  PENDINGTORECEIVEPARTS_LIST:'pendingtoreceiveparts-list',
  PRODUCTRETURNMANAGEMENTS_LIST: 'productreturnmanagements-list'
}

export {QUERIES}
