/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {adminPremission} from '../../../../utils/role'

type Props = {
  className: string
}

const MostDelayedSupportTicket: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {adminPremission() ? (
            <span className='card-label fw-bold fs-3 mb-1'>Most Delayed Support Tickets</span>
          ) : (
            <span className='card-label fw-bold fs-3 mb-1'>Delayed Tickets</span>
          )}
          <span className='text-muted fw-semibold fs-7'>Top 5 Tickets</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-light'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            View All
          </button>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle table-row-dashed fs-6 gy-5 table-striped dataTable no-footer'>
            {/* begin::Table head */}
            <thead>
              <tr className='fs-6 fw-bold text-gray-400 border-bottom-0 text-uppercase'>
                <th>Date</th>
                <th>Created By</th>
                <th>Model No.</th>
                <th>Serial No.</th>
                <th>D.O.P</th>
                <th>Dealer</th>
                <th>Customer</th>
                <th>Phone</th>
                <th>Service Center</th>
                <th>Type of Ticket</th>
                <th className='text-end'>Status</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600'>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    24/12/2022
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Jitesh Agrawal
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Amit Patel
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Hitesh Shah
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
                </td>
                <td className='text-end'>
                  <span className='badge py-3 px-4 fs-7 badge-light-warning'>On-Hold</span>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    24/12/2022
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Jitesh Agrawal
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Amit Patel
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Hitesh Shah
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
                </td>
                <td className='text-end'>
                  <span className='badge py-3 px-4 fs-7 badge-light-warning'>On-Hold</span>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    24/12/2022
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Jitesh Agrawal
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Amit Patel
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Hitesh Shah
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
                </td>
                <td className='text-end'>
                  <span className='badge py-3 px-4 fs-7 badge-light-warning'>On-Hold</span>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    24/12/2022
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Jitesh Agrawal
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Amit Patel
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Hitesh Shah
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
                </td>
                <td className='text-end'>
                  <span className='badge py-3 px-4 fs-7 badge-light-warning'>On-Hold</span>
                </td>
              </tr>
              <tr>
                <th>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    24/12/2022
                  </a>
                </th>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Jitesh Agrawal
                  </a>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>0004</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>27/10/22</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Amit Patel
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                    Mr. Hitesh Shah
                  </div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>9523145687</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Bapunagar</div>
                </td>
                <td>
                  <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>Repair</div>
                </td>
                <td className='text-end'>
                  <span className='badge py-3 px-4 fs-7 badge-light-warning'>On-Hold</span>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {MostDelayedSupportTicket}
